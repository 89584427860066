import {ContentfulHero} from "collections/contentrenderer/ContentfulHero";
import {ContentfulSectionGroup} from "collections/contentrenderer/ContentfulSectionGroup";
import {SectionGroup} from "collections/contentrenderer/SectionGroup";
import Layout from "collections/layout/Layout";
import {ProductBenefits} from "collections/product/ProductBenefits";
import {ProductMenu} from "collections/product/ProductMenu";
import SEO from "collections/SEO/core/SEO";
import {graphql} from "gatsby";
import React from "react";

export default function ProductDetailsPage({data, location}) {

  const {contentfulProduct: {benefits, hero, iconName, menuCTA, seo, slug, title, sectionGroups}} = data;
  const {featureCategories} = data;
  const {features} = data;
  // const {relatedBlogPosts: posts} = data;

  // console.log(data);

  const SEOProps = {
    title: seo?.title ? (seo?.title) : (title + " | Navatom"),
    description: seo?.description?.internal.content,
    keywords: seo?.keywords,
    // structuredData: {
    //   "@context": "http://schema.org",
    //   "@type": "WebApplication",
    //   "url": process.env.GATSBY_BASE_URL + `/products/${slug}`,
    //   "name": "Navatom " + title,
    //   "applicationCategory": "http://schema.org/WebApplication",
    //   "description": seo?.description?.internal.content,
    //   "releaseNotes": "https://push.navatom.com/en",
    //   "operatingSystem": ["Windows", "macOS", "Linux", "Android", "iOS"]
    // },
    structuredBreadcrumb: {
      "@context": "http://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": 1,
          "name": "Home",
          "item": process.env.GATSBY_BASE_URL,
        }, {
          "@type": "ListItem",
          "position": 2,
          "name": "Products",
          "item": process.env.GATSBY_BASE_URL + "products/",
        }, {
          "@type": "ListItem",
          "position": 3,
          "name": title,
          "item": `${process.env.GATSBY_BASE_URL}products/${slug}/`,
        }
      ]
    },
    // structuredProduct: {
    //   "@context": "https://schema.org/",
    //   "@type": "Product",
    //   "name": data.contentfulProduct.title,
    //   "image": [
    //     "https://example.com/photos/1x1/photo.jpg",
    //     "https://example.com/photos/4x3/photo.jpg",
    //     "https://example.com/photos/16x9/photo.jpg"
    //   ],
    //   "description": data.contentfulProduct.seo.description,
    //   "sku": `NAV-${data.contentfulProduct.slug}`,
    //   "brand": {
    //     "@type": "Brand",
    //     "name": "Navatom"
    //   },
    //   "aggregateRating": {
    //     "@type": "AggregateRating",
    //     "ratingValue": "5",
    //     "reviewCount": "4"
    //   },
    // }
  }

  const menuProps = {
    title: title,
    icon: iconName,
    slug: slug,
    hasFeatures: features.totalCount > 0,
    hasHero: hero,
    cta: menuCTA,
    location: location
  }

  return (
    <Layout>
      <SEO {...SEOProps} />
      <ProductMenu {...menuProps} />
      {hero && (<ContentfulHero data={hero} />)}
      <SectionGroup variant="primary" brightness="light">
        {benefits && (<ProductBenefits benefits={benefits} features={features} categories={featureCategories} />)}
      </SectionGroup>
      {sectionGroups && sectionGroups.map((sectionGroup, index) => (
        <ContentfulSectionGroup key={`product-sg-${index}-${sectionGroup.contentful_id}`} data={sectionGroup} />
      ))}
      {/*{posts && (*/}
      {/*  <RelatedPosts data={posts} />*/}
      {/*)}*/}
    </Layout>
  )
}

// function RelatedPosts({data}) {
//   return (
//     <SectionGroup variant="primary" brightness="light">
//       <Section>
//         {data?.nodes.map(post => (
//           <div>{post.title}</div>
//         ))}
//       </Section>
//     </SectionGroup>
//   )
// }

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulProduct(slug: {eq: $slug}) {
      id
      slug
      iconName
      title
      menuCTA { ...ContentfulCtas}
      hero {...ContentfulHero}
      seo { ...ContentfulSEO }
      sectionGroups {...ContentfulSectionGroups }
      benefits {
        title
        contentful_id
        description {
          raw
          references {
            ... on ContentfulProduct {
              contentful_id
              __typename
              title
              slug
            }
          }
        }
        images { ...ContentfulImage }
      }
    }
    features: allContentfulFeature(
      filter: {products: {elemMatch: {slug: {eq: $slug}}}}
      sort: {priority: DESC}
    ) {
      nodes {
        contentful_id
        title
        advantages
        description {
          raw
          references {
            ... on ContentfulProduct {
              __typename
              contentful_id
              title
              slug
            }
          }
        }
        benefits {
          contentful_id
          title
        }
        category {
          contentful_id
          title
        }
        images {
          contentful_id
          title
          description
          localFile {
            childImageSharp {
              thumb: gatsbyImageData(
                width: 560
                quality: 90
                layout: CONSTRAINED
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
              full: gatsbyImageData(
                layout: FULL_WIDTH)
            }
          }
        }
      }
      totalCount
    }
    featureCategories: contentfulOrderList(id: {eq: "42d58ea0-7f3a-5692-a0f6-7db0538590d2"}) {
      items {
        ... on ContentfulFeatureCategory {
          contentful_id
          title
        }
      }
    }
  }
`